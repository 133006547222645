import React, { useState, useEffect } from 'react';
import { TextField, Typography, LinearProgress, Button, createTheme, ThemeProvider, useMediaQuery } from '@mui/material';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import 'tailwindcss/tailwind.css';
import { act } from 'react-dom/test-utils';

const electron = window.require ? window.require('electron') : null;
const ipcRenderer = electron ? electron.ipcRenderer : null;
const DEFAULT_TARGET_WORD_COUNT = 500;

const App = () => {
  const prefersDark = useMediaQuery('(prefers-color-scheme: dark)');
  const [text, setText] = useState('');
  const [newWordCount, setNewWordCount] = useState(0);
  const [totalWordCount, setTotalWordCount] = useState(0);
  const [canEdit, setCanEdit] = useState(false);
  const [successVisible, setSuccessVisible] = useState(false);
  const [targetWordCount, setTargetWordCount] = useState(DEFAULT_TARGET_WORD_COUNT);
  const [inputTargetWordCount, setInputTargetWordCount] = useState(DEFAULT_TARGET_WORD_COUNT);
  const [lastResetTextLength, setLastResetTextLength] = useState(0);
  const [darkMode, setDarkMode] = useState(false);
  const [inElectron, setInElectron] = useState(false);
  const [activeFilePath, setActiveFilepath] = useState(null);

  useEffect(() => {
    const savedMode = localStorage.getItem('darkMode') === 'true';
    setDarkMode(savedMode);
  }, []);
  useEffect(() => {
      const savedText = localStorage.getItem('text');
      const savedTargetWordCount = localStorage.getItem('targetWordCount');
      const savedFilePath = localStorage.getItem('activeFilePath') === "null" ? null : localStorage.getItem('activeFilePath');
    
      if (savedText) {
        setText(savedText);
        const words = savedText.trim().split(/\s+/).filter(word => word !== '');
        setNewWordCount(words.length);
        setTotalWordCount(words.length);
      }
    
      if (savedTargetWordCount) {
        setTargetWordCount(Number(savedTargetWordCount));
        setInputTargetWordCount(Number(savedTargetWordCount));
      }

      if (savedFilePath) {
        setActiveFilepath(savedFilePath);
      }
  }, []);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf(' electron/') > -1) {
      setInElectron(true);
    }
  }, []);

  // Save As
  useEffect(() => {
    // Add IPC event listeners

    const handlePrepSave = (event, filePath) => {
      setActiveFilepath(filePath);
      ipcRenderer.send('save-with-data', { text, newWordCount, targetWordCount, filePath });
    }

    const handleSaveCurrent = (event) => {
      if (activeFilePath) {
        const filePath = activeFilePath;
        ipcRenderer.send('save-with-data', { text, newWordCount, targetWordCount, filePath });
      } else {
        ipcRenderer.send('need-save-as');
      }
    }
  
    if (ipcRenderer) {
      ipcRenderer.on('save-file-as', handlePrepSave);
      ipcRenderer.on('save-current-file', handleSaveCurrent);
    }
  
    // Clean up
    return () => {
      if (ipcRenderer) {
        ipcRenderer.removeListener('save-file-as', handlePrepSave);
        ipcRenderer.removeListener('save-current-file', handleSaveCurrent);
      }
    };
    }, [text, newWordCount, targetWordCount, activeFilePath]);


    // Load
    useEffect(() => {
      const handleFileOpened = (event, {content, db_newWordCount, db_targetWordCount, filePath}) => {
        setText(content);
        setNewWordCount(db_newWordCount); 
        setTargetWordCount(db_targetWordCount);
        setInputTargetWordCount(db_targetWordCount);
        setActiveFilepath(filePath);
      };

      const createNewFile = (event) => {
        setText('');
        setNewWordCount(0); 
        setTargetWordCount(DEFAULT_TARGET_WORD_COUNT);
        setInputTargetWordCount(DEFAULT_TARGET_WORD_COUNT);
        setActiveFilepath(null);
      }
    
      if (ipcRenderer) {
        ipcRenderer.on('file-opened', handleFileOpened);
        ipcRenderer.on('create-new-file', createNewFile);
      }

      return () => {
        if (ipcRenderer) {
          ipcRenderer.removeListener('file-opened', handleFileOpened);
          ipcRenderer.removeListener('create-new-file', createNewFile);
        }
      };
    }, []);
    
    // Methods to call IPC save and load
    const saveFile = (content, filePath = null) => {
      ipcRenderer.send('save-file', { content, filePath });
    };
    
    const loadFile = () => {
      ipcRenderer.send('load-file');
    };
  
  const isCompletedToday = () => {
    const completedTime = localStorage.getItem('completedTime');
    if (!completedTime) return false;
  
    const nowET = new Date().toLocaleString("en-US", { timeZone: "America/New_York" });
    const nowETDate = new Date(nowET);
    const startOfTodayET = new Date(nowETDate.setHours(0, 0, 0, 0));
  
    return new Date(completedTime) >= startOfTodayET;
  };


  useEffect(() => {
    const words = text.trim().split(/\s+/).filter(word => word !== '');
    const wordCount = words.length;
    setTotalWordCount(wordCount);

    const newWordsSinceReset = text.slice(lastResetTextLength).trim().split(/\s+/).filter(word => word !== '').length;

    if (!canEdit && newWordsSinceReset >= targetWordCount) {
      setCanEdit(true);
    }

    setNewWordCount(newWordsSinceReset); // Update to count only new words since the last reset
  }, [text, targetWordCount, canEdit, lastResetTextLength]);


  useEffect(() => {
    localStorage.setItem('text', text);
    localStorage.setItem('targetWordCount', targetWordCount);
    if (electron) {
      localStorage.setItem('activeFilePath', activeFilePath);
    }
  }, [text, targetWordCount, activeFilePath]);

  const toggleDarkMode = () => {
    localStorage.setItem('darkMode', !darkMode);
    setDarkMode(!darkMode);
  }

  const handleChange = (event) => {
    if (canEdit || event.target.value.length >= text.length) {
      setText(event.target.value);
    }
  };

  const handleSetTargetWordCount = () => {
    setTargetWordCount(Number(inputTargetWordCount));
  };

  const desiredWordCountReached = newWordCount >= targetWordCount;

  const handleResetProgress = () => {
    setNewWordCount(0);
    setCanEdit(false);
    setLastResetTextLength(text.length); // Update the text length at the time of reset
  };

  const clearTextArea = () => {
    setText("");
    setNewWordCount(0);
    setLastResetTextLength(0);
    localStorage.setItem('text', '');
  };


  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
    },
  });

  return (
    <ThemeProvider theme={theme}>
    <div className={`${darkMode ? 'dark' : ''}`}>
    <div className="flex justify-center min-h-screen p-4 bg-white dark:bg-gray-800">
      <div className="lg:w-1/2 md:w-full sm:w-full my-8 flex flex-col dark:text-white">
      <Button 
      onClick={toggleDarkMode} 
      className="mb-4 self-end"
      startIcon={darkMode ? <DarkModeIcon /> : <LightModeIcon />}
    >
  
    </Button>
    { !inElectron ? 

<><div className="ml-auto mr-auto font-bold ">
  <Typography style={{fontSize: "2.5em", fontWeight: 700}} color="inherit">Seatbelt</Typography>
</div>
<div className="ml-auto mr-auto mb-20">
<Typography style={{fontSize: "1.2em"}} color="inherit">No editing until you reach your target word count.</Typography>
</div></> :   <><div className="ml-auto mr-auto font-bold ">
  <Typography style={{fontSize: ".8em", fontWeight: 800, textTransform: 'uppercase', opacity: .2}} color="inherit">{activeFilePath ? activeFilePath.split('/').pop() : ""}</Typography>
</div><div className="ml-auto mr-auto mb-20">
<Typography style={{fontSize: "1.2em"}} color="inherit"></Typography>
</div></> }
      <div className="flex justify-start mb-4 items-center">
      <TextField
        label="Target Word Count"
        value={inputTargetWordCount}
        onChange={(e) => setInputTargetWordCount(e.target.value)}
        className="w-1/4 mr-2"
        style={{ height: '40px' }} // Adjust the height to match the button
        InputProps={{ style: { height: '40px' }}} // Adjust inner input height
      />
      <Button 
        variant="outlined" 
        onClick={handleSetTargetWordCount}
        style={{ height: '40px', marginLeft: '5px' }}
      >
        Set New Target
      </Button>
      <Button 
      variant="outlined" 
      style={{ height: '40px', marginLeft: '5px' }}
      onClick={handleResetProgress}
    >
      Reset Progress
    </Button>
    { !inElectron ?       <Button 
      variant="outlined" 
      onClick={clearTextArea}
      style={{ height: '40px', marginLeft: '5px' }}
    >
      Clear Text
    </Button> : "" }
    </div>
        <TextField
          spellCheck="false"
          fullWidth
          multiline
          variant="outlined"
          value={text}
          onChange={handleChange}
          placeholder="Write here..."
          InputProps={{
            readOnly: !canEdit && newWordCount >= targetWordCount
          }}
          style={{ 
            maxHeight: '80vh', 
            overflowY: 'auto',
        }}
        />
        <div className="flex justify-between items-end mt-4">
            { canEdit ? <Typography 
            color="inherit"
              variant="p"
              className={`${desiredWordCountReached ? 'text-xs sm:text-xxs text-gray-400' : 'text-xs sm:text-xxs text-black'}`}
            >
              Target Reached. Editing Enabled.
            </Typography> : <Typography 
            color="inherit"
              variant="p"
              className={`${desiredWordCountReached ? 'text-xs sm:text-xxs text-gray-400' : 'text-xs sm:text-xxs text-black'}`}
            >
              New Words: {canEdit ? <span style={{color:'green', fontWeight: 700}}>{newWordCount}</span> : newWordCount } out of <strong>{targetWordCount}</strong>
            </Typography> }
          <Typography 
            variant="p"
            className='text-xs sm:text-xxs'
          >
            Total Word Count: {totalWordCount}
          </Typography>
        </div>
        {canEdit ? "" : (
          <LinearProgress 
            variant="determinate" 
            value={Math.min((newWordCount / targetWordCount) * 100, 100)} 
            className="mt-4"
          />
        )}
      </div>
    </div>
    </div>
    </ThemeProvider>
  );
};

export default App;
